.icon-spin {
    animation: spin 1s linear infinite;
    margin-right: 8px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .toast-content{
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  