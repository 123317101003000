@import './common.scss';

$jcb-euro-font: "assets/fonts/";

@font-face {
  src: url("#{$jcb-euro-font}/JCB-Euro-Rom.TTF") format("truetype");
  font-family: "Jcb-Euro-Regular";
}
@font-face {
  src: url("#{$jcb-euro-font}/JCB_Euro_Bld.TTF") format("truetype");
  font-family: "Jcb-Euro-Bold";
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: "Jcb-Euro-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button{
  font-family: "Jcb-Euro-Regular";
  cursor: pointer;
}
// #root{
  // min-height: 100vh;
// }
img{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container{
  max-width: 1280px;
  margin: 0 auto;
}
.right-layout{
  width: calc(100% - 250px);
  background: #E4E7EA;
  padding: 20px;
  height: calc(100vh - 80px);
  overflow: auto;
  min-height: calc(100vh - 80px);
  @include transition(0.3s ease all);
  @include for-size(tablet-only){
      width: 100%;
  }
  @include for-size(mobile-only){
    padding: 0;
  }
  @include for-custom(600px){
    min-height: calc(100vh - 80px);
  }
}
.full-layout{
  width: calc(100% - 0px);
  background: #E4E7EA;
  padding: 20px;
  min-height: calc(100vh - 80px);
  @include transition(0.3s ease all);
  @include for-size(tablet-only){
      width: 100%;
  }
  @include for-size(mobile-only){
    padding: 0;
  }
  @include for-custom(600px){
    min-height: calc(100vh - 80px);
  }
}
.main-layout-box{
  background: $white;
  min-height: calc(100vh - 120px);
  border-radius: 5px;
  box-shadow: 0px 0px 5px #0000001a;
  @include for-size(tablet-only){
    min-height: calc(100vh - 80px);
  }
  @include for-size(mobile-only){
    box-shadow: none;
  }
}
.profile-login{
  &.main-layout-box{
    min-height: calc(100vh - 150px);
    margin-top: 10px;
  }
}
.change-password-login{
  &.main-layout-box{
    min-height: calc(100vh - 150px);
    margin-top: 10px;
  }
}
.diganostic-external{
  width: 100%;
}
.main-layout-transparent{
  background-color: transparent;
  min-height: calc(100vh - 170px);
  min-height: auto;
  box-shadow: 0px 0px 0px;
}
.user-dashbaord {
  position: relative;
  @include flex-component();
  @include flex-wrap();
}
.zoom-hover{
  figure{
    overflow: hidden;
  }
  img{
    @include transition(0.3s ease all);
  }
  &:hover{
    img{
      transform: scale(1.05);
    }
  }
}
.custom-scroll{
  &::-webkit-scrollbar {
    width: 8px;
    @include for-size(mobile-only){
      height: 0 !important;
    }
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;    
  }
  &::-webkit-scrollbar-thumb {
    background: #515973; 
    border-radius: 3px;    
    @include for-size(mobile-only){
      visibility: hidden;
    }
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;     
  }
}
/*loader*/
.loader-content {
  padding: 10px;
  width: 100%;
  height: 100vh;
  @include flex-component();
  @include align-items(center);
  @include justify-content(center,center);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #f2f2f2;
}
input[type="radio"]{
  -webkit-appearance:none;
  width:13px;
  height:13px;
  border: 1.5px solid darkgray;
  border-radius:50%;
  cursor: pointer;
  outline:none;
  position: relative;
  top: 1px;
   &:before{
    content:'';
    display:block;
    width:64%;
    height:64%;
    margin: 20% auto;    
    border-radius:50%;   
   }
   &:checked {
    color: $primary-color;
    border-color: $primary-color;
    &:before{
      background: $primary-color;
    }
  }
}
.checkbox_field input{
  background-color: #ffffff;
}
input[type="checkbox"]{
  -webkit-appearance: none;
  width:12px;
  height:12px;
  border:1px solid darkgray;
  outline:none;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  &:checked{
    background-color: $primary-color;
    border-color: $primary-color;
    &:before{
      position: absolute;
      left: -3px;
      top: 47%;
      height: 41%;
      width: 2px;
      background-color: $white;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
    &:after{
      position: absolute;
      left: -2px;
      bottom: 3px;
      height: 2px;
      width: 65%;
      background-color: $white;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
  }
}
.no-data-found{
  max-width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 5px;
  margin: 0 auto;
  img{
    max-width: 50px !important;
    margin-bottom: 5px;
  }
  figure {
    text-align: center;
  }
  h3, figcaption{
    font-size: 13px !important;
    text-align: center !important;
    font-weight: 500 !important;
    color: #515973 !important;
  }
  &.fullHgt {
    min-height: 60vh;
    justify-content: center;
  }
}
textarea{
  border-radius: 5px;
}
select{
  background-color: $white;
  padding: 5px;
  -webkit-appearance: none;
  option{
    padding: 8px;
  }
}
.loader-content img {
  width: 25px;
  @include animation(loaderRotate 1.5s infinite);
  animation-timing-function: linear;
}
h1,h2,h3,h4,h5,h5,h6{
  font-weight: 500;
}
.login-loader{
  background-color: $smoke !important;
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px 10px 35px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  border: 0;
  color: $white;
  font-size: rem(13);
  img{
    position: absolute;
    left: 10px;
    max-width: 20px;
    top: 8px;
  }
}
.img-loader{
  @include animation(loaderRotate 1.5s infinite);
  animation-timing-function: linear;
}

@keyframes loaderRotate {
  0%{
    @include transform(rotate(0deg));
  }
  100%{
    @include transform(rotate(360deg));
  }
}
ul.faultcode-list {
  background: #F2F2F2;
  list-style: none;
  padding: 18px 0;
  @include flex-component();
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}
ul.faultcode-list li .listName {
  display: inline-block;
  padding: 0 22px;
  border-right: 1px solid #C6C6C6;
  color: #666666;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
}
ul.faultcode-list li.faultcode-active span:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $primary-color;
  left: 0;
  bottom: -18px;
}
button.login-btn,a.login-btn {
  background: $primary-color;
  color: $black;
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px 10px 35px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  border: 0;
}
.login-logo {
  max-width: 170px;
  margin: 0 auto 30px;
  display: none;
}
// .login-loader{
//   background-color: $smoke !important;
//   img{
//     width: 20px;
//     top: 24%;
//     @include animation(loaderRotate 2s infinite cubic-bezier(0.18, 0.89, 0.32, 1.28));
//   }
// }
@-webkit-keyframes loaderRotate {
  0% {
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}

@keyframes loaderRotate {
  0% {
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}
.login-btn img,.login-btn svg {
  position: absolute;
  text-align: center;
  top: 52%;
  @include transform(translateY(-50%));
  left: 10px;
  max-width: 20px;
}
.custom-toaster{
  z-index: 999999;
  &.custom-err-message{    
    background: #ffdede;
    color: #000;
    .Toastify__progress-bar--error {
      background: rgb(248, 2, 2) !important;
    }
    .Toastify__close-button--light {
      color: #e74d3d;
      opacity: 0.5;
    }
  }
  .Toastify__toast-body{
    div:last-child{
      font-size: 14px;
      font-family: "Jcb-Euro-Regular";
    }
    .Toastify__toast-icon{
      width: 17px;
      margin-right: 5px;
    }
  }
  &.custom-success-message{    
    background: #DEF8DD;
    color: #000;
    .Toastify__progress-bar--error {
      background: #07C200 !important;
    }
    .Toastify__close-button--light {
      color: #000;
      opacity: 0.5;
    }
  }
  &.custom-warning-message{    
    background: #ffeec2;
    color: #000;
    .Toastify__progress-bar--error {
      background: $primary-color !important;
    }
    .Toastify__close-button--light {
      color: #000;
      opacity: 0.5;
    }
  }
}
.popup-layout {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}  
.popup-overlay {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  height: 100vh;
}

.popup-box {
  width: 516px;
  max-width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  top: 50%;
  @include transform(translateY(-50%));
  max-height: 562px;
  display: flex;
  flex-direction: column;
  @include for-size(large-desktop){
    max-height: 620px;
  }
  &.log{
    width: 670px;
  }
  &.flashInfo{
    width: fit-content;
  }
  &.flash-view{
    max-height: 700px;
  }
}
.ecu-figure {
  min-width: 35px;
  max-width: 35px;
  padding: 5px !important;
}
.popup-title {
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #ddd;
  h4 {
    font-size: 14px;
    font-weight: 400;
  }
}
.popup-bottom {
  padding: 15px 15px 5px 15px;
  position: relative;
  border-top: 1px solid #ddd;
  h4 {
    font-size: 14px;
    font-weight: 400;
  }
}
body.hide-scroll{
  overflow-y: hidden;
}
.popup-content {
  background: #fff;
  padding: 20px 15px 10px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-y: auto; 
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  @include transform(translateY(-50%));
  cursor: pointer;
  @include transition(0.3s ease all);
  border-radius: 50%;
}
.disabled-btn{
  opacity: .3;
  cursor: not-allowed;
}
.all-loader{
  padding: 0 10px 0 0;
  .loader-service{
    flex-direction: row;
    padding: 0;
    img{
      margin-right: 10px;
      width: 20px;
    }
    h4{
      margin-top: 0;
    }
  }
}
.loader-timer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.notes{
  display: block;
  font-size: 15px;
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
}
.button-send-list{
  display: flex;
  &.ref-icon{
    position: absolute;
    right: 25px;
    bottom: 10px;
  }
  button{
    display: flex;
    align-items: center;
    padding: 10px 18px;
    border: 0;
    border-radius: 5px;
    font-size: 13px;
    @include for-custom(350px){
      padding: 10px 5px;
    }
    img{
      @include for-custom(350px){
        display: none;
      }
    }
    &.btn-grey{
      margin-right: 10px;
      color: $black;
      img,svg{
        height: 12px;
        margin-right: 5px;
      }
    }
    &.refresh-btn{
      background-color: $primary-color;
      color: $black;
      img,svg{
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
.close-icon:hover {
  @include transform(translateY(-50%) rotate(180deg));
  @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
}
#root{
  margin-top: 80px;
  @include for-custom(600px){
    margin-top: 60px;
  }
}
.sidebar-active{
    .right-layout{
      @include for-size(tablet-only){
        width: 100% !important;
      }
    }
}
.right-layout{    
  @include for-size(large-desktop){
   width: calc(100% - 300px);  
  }
}
.table-spacing{
  padding: 20px;
  @include for-size(tablet-only){
    padding: 20px 15px;
  }
}
input{
  border-radius: 5px;
  font-family: "Jcb-Euro-Regular";
  &:focus{
    border-color: $primary-color !important;
  }
}
select {
  border-radius: 5px;
  font-family: "Jcb-Euro-Regular";
  &:focus{
    border-color: $primary-color !important;
  }
}
.user-sort{
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url('../src/assets/images/sort.svg');
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-size: 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.err-message-vin{
  color: #ce0000;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 10px 10px 0px;
}
.table-responsive {
  width: 100%;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;    
    // border: 1px solid $smoky-white;
    tr{
      .table-wrap{
        white-space: normal !important;
      }
      td{
        white-space: nowrap;        
        padding: 10px 15px;
        @include for-size(mobile-only){
          white-space: nowrap !important;
        }
        &.wrap-content{
          white-space: break-spaces;
        }
        .table-search{
          width: 100%;
          max-width: 175px;
          min-width: 60px;
          position: relative;
          input{
            padding: 5px 30px 5px 10px;
            border: 1px solid $smoky-white;
            border-radius: 5px;
            width: 100%;
            font-size: rem(12);
            &:focus{
              border-color: $primary-color;
            }
          }
          select {
            width: 100%;
            max-width: 120px;
            min-width: 120px;
            border: 1px solid #e1e1e1;
            padding: 7px;
            border-radius: 5px;
            font-size: rem(12);
            &:focus{
              border-color: $primary-color;
            }
          }
          img{
            position: absolute;
            right: 10px;
            top: 50%;
            width: 16px;
            @include transform(translateY(-50%));
            opacity: 0.5;
            cursor: pointer;
          }
        }
        .reset{
          button{
            background: transparent;
            border: 0;
            cursor: pointer;
          }
        }
        span{
          font-size: rem(13);
          color: #666;
          @include for-size(large-desktop){
            font-size: rem(15);
          }
        }
        a{
          text-decoration: none;
        }
      }
    }
    tbody{
      tr{
        border-bottom: 1px solid $smoky-white;
        td{
          span{
            display: block;
          }
          span.hide-flow-td{
            max-width: 30ch;
            overflow: hidden;
            text-overflow: ellipsis;
            &.ecuname{
              max-width: 20ch;
            }
            &.filename{
              max-width: 40ch;
              white-space: initial;
            }
            &.updatedat{
              max-width: 17ch;
            }
            &.desc{
              max-width: 70ch;
              // max-width: 60%;
              // text-overflow: initial;
              // overflow: visible;
              // word-break: initial;
              // white-space: normal;
            }
          }
          span.hide-flow-td-50ch{
            max-width: 30ch;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          span.document-50ch{
            max-width: 50ch;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }
    }
    thead{
      tr{
        td{
          position: relative;
          background: #F2F2F2;
          &::before{
            content: '';
            position: absolute;
            width: 1px;
            height: 20px;
            background-color: #C6C6C6;
            right: 0;
            top: 50%;
            @include transform(translateY(-50%));
          }
          &:last-child:before{
            display: none;
          }
        }
      }
    }
    .no-data-table{
      td{
        text-align: center;
        padding: 50px 20px;
        img{
           max-width: 100px;
        }
      }
    }
  }
}
.user-action.min_width{
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.popup-service-content{
  padding: 0;
}
.loader-page{
  display: flex;
  min-height: calc(100vh - 160px);
  align-items: center;
  justify-content: center;
}
.save-cancel-btn{
  margin-left: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
  width: 100%;
  @include for-custom(550px){
      margin-left: rem(0);
      margin-top: rem(20);
  }
  button{
      padding: 12px 40px;
      font-size: 14px;
      border: 0px;
      border-radius: 5px;
      cursor: pointer;
      color: $black;
      @include transition(0.3s ease all);
      @include for-custom(550px){
          margin-right: rem(10);
          margin-bottom: 5px;
          padding: 10px 30px;
      }
      &:hover{
          @include box-shadow(0px 0px 10px rgba(87,87,87,0.3))
      }
  }
  .cancel-btn{
      background-color: $ivory-white;

  }
  .save-btn{
      background-color: $primary-color;
      margin-left: 15px;
  }
  .login-loader-btn{
      background-color: $smoke !important;
      color: #fff;
      img{
          width: 15px;
          margin-right: 7px;
          position: relative;
          top: -1px;
          @include animation(loaderRotate 2s infinite cubic-bezier(0.18, 0.89, 0.32, 1.28));
      }
  }
}
.select-custom-status-mds{
  width: fit-content;
  position: relative;
  select{
    cursor: pointer;
    width: 70px;
  }
  
  &:before{
    content: '';
    width: 12px;
    height: 7px;
    background-image: url('./assets/images/arrow-down-select.svg');
    right: 6px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    z-index: 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.multi-select{
    &:before{
      content: none;
    }
  }
}
.select-custom{
  position: relative;
  select{
    cursor: pointer;
  }
  &:before{
    content: '';
    width: 12px;
    height: 7px;
    background-image: url('./assets/images/arrow-down-select.svg');
    right: 6px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    z-index: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.multi-select{
    &:before{
      content: none;
    }
  }
}
.width-100{
  width: 100%!important
}
.text-center{
  text-align: center!important
}
select::-ms-expand{
  display: none;
}
.user-pagination {
  @include flex-component();
  @include justify-content(justify,space-between);
  padding: 0 20px 20px;
  margin-top: 20px;
  @include for-size(mobile-only) {
      padding: 0 15px 15px;
  }
  @include for-custom(475px) {
    flex-wrap: wrap;
    justify-content: center;
}
  .pagination-lists {
      @include flex-component();
      @include align-items(center);
      span{
          font-size: 13px;
          color: #515973;
          margin-right: 15px;
          @include for-size(mobile-only) {
              margin-right: 5px;
          }
      }
      select{
          padding: 5px 20px 5px 5px;
          border: 1px solid #A3A4A5;
          border-radius: 3px;
          font-size: 13px;
          font-family: "Jcb-Euro-Regular";
          position: relative;
      }
  }
  .pagination-pages{
    @include for-custom(475px){
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
      .pagination{
          @include flex-component();
          list-style: none;
          li{
            &:first-child,&:last-child,&:nth-child(2),&:nth-last-child(2){
              a{
                border-color: $primary-color;
              }
            }
            @include for-custom(475px){
              &:first-child{
                a{
                  margin-left: 0;
                }
              }
            }
            svg{
              width: 10px;
              height: 10px;
            }
              a{
                text-decoration: none;
                color: black;
                border-radius: 5px;
                width: 30px;
                height: 30px;
                border: 1px solid #A3A4A5;
                font-size: rem(13);
                margin-left: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include transition(0.3s ease all);
                @include for-custom(475px){
                  width: 25px;
                  height: 25px;
                }
              }
              &.page-arrow{
                  border-color: $primary-color;
              }
              &.active{
                a{
                  background-color: $primary-color;
                  border-color: $primary-color;
                  color: $black;
                }
              }
              &.page-active,&:hover{
                a{
                  background-color: $primary-color;
                  border-color: $primary-color;
                  color: $white;
                }
              }
              path{
                  @include transition(0.3s ease all);
              }
              &.disabled{
                a{                  
                  cursor:not-allowed;
                  background-color: #e4e4e4;
                  border-color: #e4e4e4;
                  path{
                    fill: #8f8f8f;
                  }
                }
                &:hover{
                  a{
                    background-color: #e4e4e4;
                    border-color: #e4e4e4;
                  }
                }
              }
              &:hover{                
                path{
                  fill: $white;
                }
              }
          }
      }
  }
}
li#popup-scroll-bottom {
  padding: 0;
  height: 0;
  border: 0px;
}
.pt-100{
  padding-top: 100px
}
.word-break{
  word-break: break-all
}
.common-btn{
  background-color: $primary-color;
  border: transparent;
  color: $black;
  padding: 8px 15px;
  font-size: 13px;
  border-radius: 5px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A3A4A5;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #A3A4A5;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #A3A4A5;;
}
@import'react-toastify/dist/ReactToastify.css';

.loader-image-upload-cat-imge{
  margin-top: 5px;

.manage-model-content-img {
  width: 100px;
  height: 100px;
  // margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #d7dbe1;
  background: #fff;
  img {
      height: 100%;
      width: auto;
      object-fit: contain;
  }
}
}

.remove-cat-image-action{
    margin-top:3px;
    margin-bottom: auto;
}
.delete-icon-img{
  cursor: pointer;
}

.filter-date-picker {
  display: flex;
  align-items: center;
  width: calc(100% - 250px);
  justify-content: flex-end;
  flex-wrap: wrap;
  @include for-custom(1200px){
      width: 100%;
      justify-content: flex-start;
  }
  button.filter-button{
      padding: 6px 10px;
      background: $primary-color;
      border: 0;
      border-radius: 5px;
  }
}

.date-picker-log-box-filter {
  position: relative;
display: flex;
align-items: center;
margin-right: 15px;
@include for-custom(600px){
    width: 100%;
    margin-bottom: 15px;
}
.react-datepicker-wrapper{
    max-width: 150px;
    margin-right: 15px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        right: 10px;
        top: 10px;
        width: 15px;
        height: 15px;
        background-image: url('./assets/images/calendar.svg');
        z-index: 9;
        background-size: contain;
        background-repeat: no-repeat;
    }
    input[type="text"] {
        padding: 7px;
        border: 1.5px solid #ddd;
        margin-right: 10px;
        cursor: pointer;
        width: 100%;
    }
}
}

.log-dashboard-select-filter{
  width: 100px;
  select{
    cursor: pointer;
    border: 1.5px solid #808080   ;
    padding: 7px 20px 7px 5px;
    color: #808080;
    width: 100%;
  }
}

.down-icon-filter{
  width: 20px;
}

.select-info{
  position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 13px;
    right: 0;
    color: #A3A4A5;
}
.select-info-padding{
  padding-top: 30px !important;
}
.reset-curser{
  cursor: pointer;
}

.popup-to-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close-icon {
    position: relative !important;
    right: 0px;
    top: 12px;
  }
  .text-wrap{
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//toast bounce
.toast-bounce {
  animation: bounce 1s infinite;
}

.toast-pending {
  background-color: rgb(241, 241, 233);
  color: black;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.toster_msg {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  background: #f4f4f4;
  padding: 22px 121px 22px 20px;
}

.toster_animation{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  span{
    background: #f9b200;
    height: 4px;
    display: block;
    transition: width 0.3s;
  }
}
.toster_text{
  position: absolute;
  bottom: 23px;
  width: 83%;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}